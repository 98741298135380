<template>
  <div class="card-header">
    <!-- Title -->
    <h4 class="card-header-title">
      Filtros
    </h4>
    <button class="btn btn-sm btn-link text-reset" type="reset" @click="clearFilter()">
      <small>Limpar filtro</small>
    </button>
  </div>
  <div class="card-body">
    <div class="list-group list-group-flush mt-n4 mb-4">
      <div class="list-group-item">
        <div class="row">
          <div class="col">
            <small>Período
              <span class="fe fe-info"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Limite maximo de 30 dias de intervalo"
              ></span>
            </small>
          </div>
          <div class="col-8">
            <flat-pickr class="form-control"
                        :class="dateError ? 'is-invalid' : ''"
                        v-model="date"
                        :config="datePickerConfig">
            </flat-pickr>
            <div v-if="dateError" class="invalid-feedback">
              <small>Periodo inválido, intervalo máximo 30 dias, e mínimo 1 dia.</small>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col">
            <small>Status</small>
          </div>
          <div class="col-8">
            <Multiselect
                v-model="filter.status"
                :options="status"
                mode="tags"
                :closeOnSelect="false"
                label="name"
                trackBy="value"
            >
              <template v-slot:tag="{ option, handleTagRemove, disabled }">
                <div class="multiselect-tag">
                  {{ option.name }}

                  <span
                      v-if="!disabled"
                      class="multiselect-tag-remove"
                      @mousedown.prevent="handleTagRemove(option, $event)"
                  >
                      <span class="multiselect-tag-remove-icon"></span>
                    </span>
                </div>
              </template>
            </Multiselect>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col">
            <small>Qualificações</small>
          </div>
          <div class="col-8">
            <Multiselect
                v-model="filter.qualifications"
                :options="qualifications"
                mode="tags"
                :closeOnSelect="false"
                label="name"
                trackBy="value"
            >
              <template v-slot:tag="{ option, handleTagRemove, disabled }">
                <div class="multiselect-tag">
                  {{ option.name }}

                  <span
                      v-if="!disabled"
                      class="multiselect-tag-remove"
                      @mousedown.prevent="handleTagRemove(option, $event)"
                  >
                      <span class="multiselect-tag-remove-icon"></span>
                    </span>
                </div>
              </template>
            </Multiselect>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col">
            <small>Usuários</small>
          </div>
          <div class="col-8">
            <Multiselect
                v-model="filter.users"
                :options="users"
                mode="tags"
                :closeOnSelect="false"
                label="name"
            >
              <template v-slot:tag="{ option, handleTagRemove, disabled }">
                <div class="multiselect-tag">
                  {{ option.name }}

                  <span
                      v-if="!disabled"
                      class="multiselect-tag-remove"
                      @mousedown.prevent="handleTagRemove(option, $event)"
                  >
                      <span class="multiselect-tag-remove-icon"></span>
                </span>
                </div>
              </template>
            </Multiselect>
          </div>
        </div>
      </div>
    </div>
    <button class="btn w-100 btn-primary" @click="setFilter()">
      Aplicar Filtro
    </button>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Portuguese} from "flatpickr/dist/l10n/pt.js"

import Multiselect from '@vueform/multiselect'
import moment from "moment";
import {Tooltip} from "bootstrap";

export default {
  name: "table-filter",
  components: {flatPickr, Multiselect},

  data() {
    return {
      filter: {
        dates: {
          start_date: moment().subtract(30, 'days').format('yyyy-MM-DD 00:00:00'),
          end_date: moment().format('yyyy-MM-DD 23:59:59'),
          start_date_to_qualification: moment().subtract(30, 'days').format('yyyy-MM-DD'),
          end_date_to_qualification: moment().format('yyyy-MM-DD'),
        }
      },

      date: moment().subtract(30, 'days').format('yyyy-MM-DD')
          + ' até '
          + moment().format('yyyy-MM-DD'),
      dateError: null,

      datePickerConfig: {
        mode: "range",
        date: moment().format(),
        locale: Portuguese,
      },

      isFiltered: false,
    }
  },

  computed: {
    users() {
      let users = this.$store.getters['users/getUsers'].data;

      for (let i = 0; i < users.length; i++) {
        users[i].value = users[i].id;
      }

      return users;
    },
    status() {
      return [
        {
          value: 8,
          name: 'Falha'
        }, {
          value: 5,
          name: 'Não atendida'
        }, {
          value: 7,
          name: 'Atendida'
        },
      ]
    },
    qualifications() {
      return [
        {
          value: -2,
          name: 'Não qualificada'
        },
        {
          value: -3,
          name: 'Caixa postal',
        },
        {
          value: -4,
          name: 'Mudo'
        }
      ]
    },
    activeFilter() {
      return this.$store.getters['reportCalls/getFilter']
    }
  },

  watch: {
    date() {
      if (this.date == null) {
        this.dateError = false;
        return;
      }

      let dates = this.date.split(' até ');

      if (dates.length < 2) {
        this.dateError = true;
      } else {
        const diff = moment(dates[0], 'yyyy-MM-DD').diff(moment(dates[1], 'yyyy-MM-DD'), 'days');
        if (Math.abs(diff) > 30) {
          this.dateError = true;
        } else {
          this.dateError = false;
          this.filter.dates = {
            start_date: moment(dates[0]).format('yyyy-MM-DD 00:00:00'),
            end_date: moment(dates[1],).format('yyyy-MM-DD 23:59:59'),
            start_date_to_qualification: moment(dates[0]).format('yyyy-MM-DD'),
            end_date_to_qualification: moment(dates[1],).format('yyyy-MM-DD'),
          };
        }
      }
    },

    activeFilter() {
      this.$store.dispatch('reportCalls/fetchCalls', {page: 1, search: ''});
    }
  },

  methods: {
    setFilter() {
      this.$store.dispatch('reportCalls/setFilter', this.filter);
      this.isFiltered = true;
    },

    clearFilter() {
      this.filter = {};
      this.date = moment().subtract(30, 'days').format('yyyy-MM-DD')
          + ' até '
          + moment().format('yyyy-MM-DD');
      this.dateError = false;
    }
  },

  created() {
    this.$store.dispatch('users/fetchUsers', {page: 1, search: ''});
  },

  mounted() {
    this.$store.dispatch('reportCalls/setFilter', null);

    Array.from(document.querySelectorAll('span[data-bs-toggle="tooltip"]'))
        .forEach(tooltipNode => new Tooltip(tooltipNode))
  },
}
</script>

<style scoped>
.multiselect-tag {
  background: #edf2f9 !important;
  color: #727272 !important;
}

.fe-check-circle {
  color: green;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="card-body"] {
    width: 100% !important;
  }
}

@media only screen and (min-width: 769px) {
  /* For mobile phones: */
  [class*="card-body"] {
    width: 450px !important;
    max-height: 450px !important;
  }
}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>
