<template>
  <layout header-pre-title="Relatórios" header-title="Chamadas">
    <template v-slot:content>
      <Table></Table>
      <Metrics></Metrics>
      <div class="col-12 col-lg-8 col-xl">
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">
              Qualificações
            </h4>
          </div>
          <div class="card-body">
            <qualification-donut-chart :qualifications="qualifications"></qualification-donut-chart>
          </div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from "../../components/layout/Layout";
import Table from "../../components/reports/calls/Table";
import Metrics from "../../components/reports/calls/Metrics";
import QualificationDonutChart from "../../components/common/QualificationDonutChart";

export default {
  components: {QualificationDonutChart, Metrics, Table, Layout},

  computed: {
    qualifications() {
      return this.$store.getters['reportCalls/getQualifications'];
    }
  },

  mounted() {
    this.$store.dispatch('reportCalls/fetchQualifications');
  }
}
</script>

<style scoped>

</style>
