<template>
  <pagination-table action="reportCalls/fetchCalls" :meta="calls.meta">
    <template v-slot:columns>
      <th scope="col">
        <a href="#" class="text-muted">Número</a>
      </th>
      <th scope="col">
        <a href="#" class="text-muted list-sort">Data</a>
      </th>
      <th scope="col">
        <a href="#" class="text-muted list-sort">Duração</a>
      </th>
      <th scope="col">
        <a href="#" class="text-muted list-sort">Valor</a>
      </th>
      <th scope="col">
        <a href="#" class="text-muted list-sort">Status</a>
      </th>
      <th scope="col">
        <a href="#" class="text-muted list-sort">Qualificação</a>
      </th>
      <th scope="col">
        <a href="#" class="text-muted list-sort">Usuário</a>
      </th>
      <th scope="col">
        <a href="#" class="text-muted list-sort">Gravação</a>
      </th>
    </template>

    <template v-slot:active-filter>
      <span v-if="isFiltered > 0" class="badge bg-primary ms-1">
        {{ isFiltered }}
      </span>
    </template>

    <template v-slot:filter>
      <table-filter></table-filter>
    </template>

    <template v-slot:content>
      <tr v-for="call in calls.data" :key="call.id">
        <th scope="row" class="tables-number">{{ formatter.phoneNumber(call.number) }}</th>
        <td class="tables-date">{{ formatter.datetimePtBr(call.call_date) }}</td>
        <td class="tables-duration">{{ call.speaking_time }}</td>
        <td class="tables-value">{{ call.billed_value }}</td>
        <td class="tables-status">
          <span class="badge" :class="badgeClass(call.readable_status_text)">
            {{ call.readable_status_text === 'Finalizada' ? 'Conectada' : call.readable_status_text }}
          </span>

        </td>
        <td class="tables-qualification">{{ call.qualification }}</td>
        <td class="tables-agent">{{ call.agent }}</td>
        <td class="tables-recording">
          <audio controls preload="none" controlsList="nodownload">
            <source :src="call.recording " type="audio/mpeg">
          </audio>
        </td>
      </tr>
    </template>
  </pagination-table>
</template>

<script>
import formatter from '../../../util/formatter'
import PaginationTable from "../../common/PaginationTable";
import TableFilter from './TableFilter';

export default {
  name: "Table",

  components: {PaginationTable, TableFilter},

  data() {
    return {
      formatter: formatter,
      date: null,
    }
  },

  computed: {
    calls() {
      return this.$store.getters['reportCalls/getCalls'];
    },

    isFiltered() {
      return this.$store.getters['reportCalls/getIsFiltered'];
    },
  },

  methods: {
    clearFilter() {
      this.$store.dispatch('reportCalls/setFilter', {});
    },

    badgeClass(status) {
      if (status === 'Finalizada') {
        return 'bg-success-soft'
      }

      if (status === 'Falha') {
        return 'bg-secondary-soft'
      }

      if (status === 'Não atendida') {
        return 'bg-warning-soft'
      }
    }
  }
}
</script>

<style scoped>
audio {
  height: 20px !important;
  width: 250px !important;
}
audio::-webkit-media-controls-mute-button {
  display: none !important;
}
audio::-webkit-media-controls-volume-slider {
  display: none !important;
}
</style>