<template>
  <div class="row">
    <div v-for="metric in metrics" :key="metric" class="col-12 col-lg-6 col-xl">
      <div class="card">
        <div class="card-body">
          <div class="row align-items-center gx-0">
            <div class="col">
              <h6 class="text-uppercase text-muted mb-2">{{ metric.label }}</h6>
              <span class="h2 mb-0">{{ metric.value }}</span>
            </div>
            <div class="col-auto">
              <span :class="`h2 ${metric.icon} text-muted mb-0`"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Metrics",

  created() {
    this.$store.dispatch('reportCalls/fetchCallsTotal');
  },

  computed: {
    metrics() {
      const metrics = this.$store.getters['reportCalls/getMetrics'];

      return [
        {
          value: metrics.all?.all?.calls_count ?? 0,
          label: 'Ligações realizadas',
          icon: 'fe fe-phone'
        }, {
          value: metrics.all?.all?.calls_count > 0
              ? ((metrics.answered?.all?.calls_count * 100) / metrics.all?.all?.calls_count).toFixed(2)
              : 0,
          label: 'Completamento',
          icon: 'fe fe-percent'
        }, {
          value: metrics.answered?.all?.calls_count ?? 0,
          label: 'Ligações atendidas',
          icon: 'fe fe-phone-call'
        }, {
          value: metrics.all?.all?.billed_value ?? "R$ 0,000",
          label: 'Total Tarifado',
          icon: 'fe fe-dollar-sign'
        },
      ];
    },
  }
}
</script>

<style scoped>
</style>
