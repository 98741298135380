<template>
  <div>
    <div v-if="qualifications.length > 0" class="chart chart-appended">
      <canvas class="chart-canvas" id="qualificationChart" data-toggle="legend"
              data-target="#trafficChartLegend"></canvas>
    </div>
    <div v-else class="d-flex justify-content-center vertical-center text-muted">
      Nenhuma ligação hoje
    </div>

    <div class="row mt-4" v-if="qualifications.length > 0">
      <div class="col-4 d-flex justify-content-center mb-3" v-for="qualification in qualifications"
           :key="qualification.name">
        <div class="chart-legend-item">
           <span class="chart-legend-indicator" :style="`background-color:${qualification.color}`">
          </span>
          {{ qualification.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Chart} from 'chart.js';

export default {
  name: "QualificationDonutChart",

  props: {
    qualifications: {
      required: true,
    }
  },

  mounted: function () {
    if (this.qualifications.length > 0) {
      new Chart('qualificationChart', {
        type: 'doughnut',
        data: {
          labels: this.qualifications.map((item) => {
            return item.name
          }),
          datasets: [{
            data: this.qualifications.map((item) => {
              return item.total
            }),
            backgroundColor: this.qualifications.map((item) => {
              return item.color
            }),
          }]
        }
      });
    }
  },
}
</script>

<style scoped>
.chart {
  width: 100% !important;
}

.badge {
  font-size: 12px !important;
}

.dot {
  height: 8px;
  width: 2px;
  border-radius: 50%;
  display: inline-block;
}
</style>
