export default {
    datetimePtBr(date) {
        const newDate = new Date(date);
        return newDate.toLocaleDateString('pt-BR') + ' ' + newDate.toLocaleTimeString('pt-BR')
    },

    phoneNumber(number) {
        if(!number){
            return number;
        }
        number = number.replace(/\D/g, "");
        if (number.length >= 13) {
            number.substring(2)
        }
        number = number.replace(/^(\d{2})(\d)/g, "($1) $2");
        number = number.replace(/(\d)(\d{4})$/, "$1-$2");

        return number;
    }
}
